.animation-container {
    display: flex;
    flex-direction: column;
}

.animated-child {
    opacity: 0;
    animation: fadeInUp 1s ease-in-out forwards;
}

@keyframes fadeInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media print {
    .animated-child {
        opacity: 1 !important;
        animation: none !important;
    }
}