.table td {
    border: 1px solid black;
}

label {
    margin-right: 16px;
}

.form-group {
    margin-bottom: 16px;
}

.header {
    background-color: white;
}

.parameter {
    border-radius: 4px;
    background-color: #EEEEEE
}

@media print {
    .header, .header *, .header-hide-on-scroll {
        display: none !important;
    }


    .metrics-copy {
        text-align: left;
    }

    .collapsible-cell-content {
        display: block !important;
        background-color: #DDDDDD !important;
        padding: 8px !important;
    }

    .collapsible-cell-button {
        display: none !important;
    }

    .print-flatten {
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .print-flatten > * {
        flex-basis: 50% !important;
        max-width: unset !important;
    }
}